<template>
  <div class="strapi-product-you-tube container">
    <h2 class="strapi-product-you-tube__title mb-0">{{ component.title }}</h2>
    <div class="youtube-video mt-8 shadow">
      <iframe
        :src="component.videoUrl"
        :title="component.title"
        height="315"
        loading="lazy"
        width="560"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script lang="ts">
import StrapiImage from './StrapiImage.vue';
import type { StrapiYouTubeVideo } from '~/apollo/types/types';

export default defineComponent({
  name: 'StrapiProductYouTube',
  components: { StrapiImage },
  props: {
    component: {
      type: Object as PropType<StrapiYouTubeVideo>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.youtube-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
